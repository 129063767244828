@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;

    --animate-duration: 1s;
    --animate-delay: 1s;
    --animate-repeat: 1;
    --main-color: 195 76% 42%;
    /* --main-color: 206 100% 42%; */
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {

  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground min-w-system;
  }

}

@keyframes fallbackLoader {
  0% {
    background-position: 0% 0%;
  }


  100% {
    background-position: 100% 0%;
  }
}


@keyframes fadeHeaderBgIn {

  from {
    background-color: transparent;
  }

  to {
    background-color: hsl(var(--main-color));
  }
}

.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both
}

.animate__fadeHeaderBgIn {
  -webkit-animation-name: fadeHeaderBgIn;
  animation-name: fadeHeaderBgIn;
  animation-duration: 2000ms;
  animation-timing-function: ease-in-out;
}

.c-rich-text.c-rich-text--center {
  text-align: center;
}

.c-rich-text a {
  color: hsl(var(--main-color));
}

.c-rich-text.c-rich-text--center * {
  margin: 0 auto;
}

* {
  hyphens: auto;
  /* word-break: break-all; */
}

.fallbackLoader {
  background: linear-gradient(to right, #1A94BC, #6C4F96, #4EB5C5);
  background-size: 200%;
  animation: fallbackLoader 4s linear infinite;
}

html {
  scroll-behavior: smooth;
}

.a-link:hover {
  cursor: pointer;
  color: hsl(var(--main-color));
}